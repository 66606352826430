import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useState, useContext } from "react";
import { CurrentUserContext } from "../../../../contexts/CurrentUserContext";

export default function OrganizationSelector() {
  const { currentOrganization, userOrganizations, setOrganization } =
    useContext(CurrentUserContext);

  const organizationColor = "#ddd";
  const organizationSecondaryColor = "#333";

  const [anchorEl, setAnchorEl] = useState(null);
  const open = anchorEl !== null;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ justifyContent: "center", display: "flex", cursor: "pointer" }}>
      <IconButton
        sx={{ "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}
        size="small"
        aria-label="switch organization button"
        onClick={handleClick}
      >
        <Avatar
          sx={{
            height: 70,
            width: 70,
            bgcolor: organizationColor,
            color: organizationSecondaryColor,
            fontWeight: 700,
          }}
        >
          {currentOrganization.organizationInitials}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <MenuItem>Change Organization </MenuItem>
        <Divider />

        {userOrganizations.map((org) => (
          <MenuItem
            key={`org-${org.organizationId}`}
            onClick={() => {
              setOrganization(org.organizationId);
              handleClose();
            }}
          >
            {org.organizationName}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
