import { useState, useEffect } from "react";
import { Snacks } from "../../../contexts/Snacks";
import { Confirm } from "../../../contexts/Confirm";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useRouter } from "next/router";
import { useTheme } from "@mui/material/styles";
import MaterialLink from "@mui/material/Link";
import NextLink from "next/link";
import React from "react";
import {
  FetchContext,
  FetchContextProvider,
} from "../../../contexts/FetchContext";
import {
  CurrentUserConsumer,
  CurrentUserProvider,
} from "../../../contexts/CurrentUserContext";
import Head from "next/head";
import Snackbar from "@mui/material/Snackbar";
import Alert from "../../../contexts/Snacks/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import SearchModal from "../../../components/SearchModal/SearchModal";
import { Collapse, MenuItem } from "@mui/material";
import OrganizationSelector from "./components/OrganizationSelector";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InventoryIcon from "@mui/icons-material/Inventory";
import StorefrontIcon from "@mui/icons-material/Storefront";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import PeopleIcon from "@mui/icons-material/People";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonIcon from "@mui/icons-material/Person";
import SecurityIcon from "@mui/icons-material/Security";
import BusinessIcon from "@mui/icons-material/Business";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const navigationTheme = createTheme({
  palette: {
    primary: {
      main: "#082C3F",
    },
    success: {
      main: "#00A757",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#1565C0",
    },
    success: {
      main: "#00A757",
    },
  },
  typography: {
    fontSize: 16, // This sets the base font size to 1rem (16px by default)
    button: {
      textTransform: "none",
    },
    body1: {
      fontSize: "1rem",
    },
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2.25rem",
    },
    h3: {
      fontSize: "1.75rem",
    },
    h4: {
      fontSize: "2.125rem", // Default size for h4
    },
    h5: {
      fontSize: "1.5rem", // Default size for h5
    },
    h6: {
      fontSize: "1.25rem",
    },
  },
});

const ListButton = ({ onClick, children }) => {
  return (
    <ListItem
      onClick={onClick}
      button
      component={MaterialLink}
      style={{ color: "white", paddingTop: 10, paddingBottom: 10 }}
    >
      {children}
    </ListItem>
  );
};

const isParentPathMatch = (router, urls) => {
  const isMatch =
    router?.asPath && urls.some((href) => router.asPath.includes(href));
  return isMatch;
};

const ListLink = ({ hasAccess, href, children, indent, styles }) => {
  if (!hasAccess) {
    return false;
  }

  const router = useRouter();

  function checkIt() {
    if (
      !router.asPath.includes("list") &&
      !router.asPath.includes("fileHandler")
    ) {
      const split = href.split("/");
      return (
        split.slice(1, 2).join("/") ==
        decodeURI(router.asPath).split("/").slice(1, 2).join("/")
      );
    } else {
      return false;
    }
  }

  const active =
    (router &&
      //&& (getNavigationPath(router.asPath) === getNavigationPath(href)
      //||
      decodeURI(router.asPath) === decodeURI(href)) ||
    checkIt();
  //);
  const splitRouterPath = router.asPath.toLowerCase().split("/");

  const theme = useTheme();

  return (
    <NextLink href={href} legacyBehavior>
      <ListItem
        href={href}
        button
        component={MaterialLink}
        style={{
          color: "white",
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: active ? theme.palette.primary.light : "",
          paddingLeft: indent ? 55 : undefined,
          ...styles
        }}
      >
        {children}
      </ListItem>
    </NextLink>
  );
};

export const Providers = ({ children }) => {
  return (
    <ThemeProvider theme={navigationTheme}>
      <CurrentUserProvider>
        <FetchContextProvider>
          <Confirm>
            <Snacks>
              <FetchContext.Consumer>
                {(context) => <ProgressSnack isLoading={context.isLoading} />}
              </FetchContext.Consumer>
              {children}
            </Snacks>
          </Confirm>
        </FetchContextProvider>
      </CurrentUserProvider>
    </ThemeProvider>
  );
};

const ProgressSnack = ({ isLoading }) => {
  return (
    <Snackbar open={isLoading}>
      <Alert sx={{ width: "100%", overflow: "hidden" }} icon={false}>
        <CircularProgress
          color="inherit"
          sx={{ overflow: "hidden" }}
          data-testid="loading-data"
        />
      </Alert>
    </Snackbar>
  );
};

const ListItemIconLink = ({ icon: IconComponent, text }) => (
  <>
    <ListItemIcon sx={{ minWidth: "unset", marginRight: 2, color: "white" }}>
      <IconComponent />
    </ListItemIcon>
    <ListItemText primary={text} />
  </>
);

const Layout = ({ children }) => {
  const [seachModalOpen, setSearchModalOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "." || e.key === "`") {
        const activeTag = document.activeElement.tagName.toLowerCase();
        if (
          activeTag === "input" ||
          activeTag === "textarea" ||
          activeTag === "select" ||
          activeTag === "option"
        ) {
          return;
        }
        e.stopPropagation();
        setSearchModalOpen(!seachModalOpen);
        return false;
      } else if (e.key === "Escape") {
        console.log("Escape pressed");
        setSearchModalOpen(false);
      }
    };
    document.addEventListener("keyup", handleKeyPress);
    return () => document.removeEventListener("keyup", handleKeyPress);
  }, [seachModalOpen]);

  return (
    <CurrentUserConsumer>
      {({ permissions, signOut, isSignedIn }) => (
        <>
          <Head>
            <title>DERP</title>
          </Head>
          <Grid container sx={{ height: "100vh", overflowY: "auto" }}>
            <Grid
              item
              xs={3}
              sm={3}
              md={2}
              lg={1.5}
              xl={1.5}
              sx={{
                py: 2,
                position: "sticky",
                top: 0,
                bottom: 0,
              }}
              style={{ backgroundColor: "#082C3F" }}
            >
              <Typography variant="h5" sx={{ px: 2, py: 1, color: "white" }}>
                <OrganizationSelector />
              </Typography>
              <List>
                {isSignedIn && permissions && (
                  <>
                    <ListLink
                      hasAccess={permissions.isPurchasingManager}
                      href="/purchaseOrder/list"
                    >
                      <ListItemIconLink
                        icon={ShoppingCartIcon}
                        text="Purchasing"
                      />
                    </ListLink>
                    <ListLink
                      hasAccess={permissions.isProductManager}
                      href="/product/list"
                    >
                      <ListItemIconLink icon={InventoryIcon} text="Products" />
                    </ListLink>
                    <Collapse
                      in={isParentPathMatch(router, [
                        "/fileHandler/PRODUCT",
                        "/product",
                      ])}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <MenuItem style={{ padding: 0 }}>
                          <ListLink
                            hasAccess={permissions.isProductManager}
                            indent
                            href="/fileHandler/PRODUCT_CREATE/form"
                            styles={{paddingTop: 5, paddingBottom: 5}}
                          >
                            Bulk Create
                          </ListLink>
                        </MenuItem>
                        <MenuItem style={{ padding: 0 }}>
                          <ListLink
                            hasAccess={permissions.isProductManager}
                            indent
                            href="/fileHandler/PRODUCT_UPDATE/form"
                            styles={{paddingTop: 5, paddingBottom: 5}}
                          >
                            Bulk Update
                          </ListLink>
                        </MenuItem>
                        <MenuItem style={{ padding: 0 }}>
                          <ListLink
                            hasAccess={permissions.isProductManager}
                            indent
                            href="/fileHandler/PRODUCT_KIT_UPDATE/form"
                            styles={{paddingTop: 5, paddingBottom: 5}}
                          >
                            Bulk Kit Update
                          </ListLink>
                        </MenuItem>
                      </List>
                    </Collapse>
                    <ListLink
                      hasAccess={permissions.isVendorManager}
                      href="/vendor/list"
                    >
                      <ListItemIconLink icon={StorefrontIcon} text="Vendors" />
                    </ListLink>
                    <ListLink
                      hasAccess={permissions.isBrandManager}
                      href="/brand/list"
                    >
                      <ListItemIconLink
                        icon={BrandingWatermarkIcon}
                        text="Brands"
                      />
                    </ListLink>
                    <ListLink
                      hasAccess={
                        permissions.isOrderManager || permissions.isShipper
                      }
                      href="/order/list"
                    >
                      <ListItemIconLink icon={ReceiptIcon} text="Orders" />
                    </ListLink>
                    <Collapse
                      in={isParentPathMatch(router, ["/order/list"])}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <MenuItem style={{ padding: 0 }}>
                          <ListLink
                            indent
                            hasAccess={permissions.isShipper}
                            href="/order/list/ready to ship"
                          >
                            Ready To Ship
                          </ListLink>
                        </MenuItem>
                      </List>
                    </Collapse>
                    <ListLink
                      hasAccess={permissions.isReturnsManager}
                      href="/return/list"
                    >
                      <ListItemIconLink
                        icon={AssignmentReturnIcon}
                        text="Returns"
                      />
                    </ListLink>
                    <ListLink
                      hasAccess={permissions.isCustomerManager}
                      href="/customer/list"
                    >
                      <ListItemIconLink icon={PeopleIcon} text="Customers" />
                    </ListLink>
                    <ListLink
                      hasAccess={permissions.isWarehouseManager}
                      href="/warehouse/list"
                    >
                      <ListItemIconLink
                        icon={LocalShippingIcon}
                        text="Warehouses"
                      />
                    </ListLink>
                    <ListLink
                      hasAccess={permissions.isUserManager}
                      href="/organizationUser/list"
                    >
                      <ListItemIconLink icon={PersonIcon} text="Users" />
                    </ListLink>
                    <ListLink
                      hasAccess={permissions.isRoleManager}
                      href="/role/list"
                    >
                      <ListItemIconLink icon={SecurityIcon} text="Roles" />
                    </ListLink>
                    <ListLink
                      hasAccess={permissions.isOrganizationManager}
                      href="/organization/list"
                    >
                      <ListItemIconLink
                        icon={BusinessIcon}
                        text="Organizations"
                      />
                    </ListLink>
                    <ListLink
                      hasAccess={permissions.isReportingManager}
                      href="/dashboard"
                    >
                      <ListItemIconLink icon={DashboardIcon} text="Dashboard" />
                    </ListLink>
                    <ListLink hasAccess={true} href="/userSettings/details">
                      <ListItemIconLink icon={SettingsIcon} text="Settings" />
                    </ListLink>
                  </>
                )}

                <div>
                  {isSignedIn
                    ? // stops the flash of sign in button
                      permissions && (
                        <>
                          <ListButton onClick={signOut}>
                            <ListItemIconLink
                              icon={ExitToAppIcon}
                              text="Sign Out"
                            />
                          </ListButton>
                        </>
                      )
                    : false}
                </div>
              </List>
            </Grid>
            <Grid
              item
              xs={9}
              sm={9}
              md={10}
              lg={10.5}
              xl={10.5}
              sx={{ px: 3, pb: 1 }}
            >
              <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </Grid>
          </Grid>
          <SearchModal
            open={seachModalOpen}
            handleClose={() => setSearchModalOpen(false)}
          />
        </>
      )}
    </CurrentUserConsumer>
  );
};

export default Layout;
